<template>
  <div class="js-common-wrap js-standard-operation">
    <div class="js-common-head">
      <div>
        <el-button
          class="back-btn"
          @click="$goBack"
          icon="el-icon-arrow-left"
          size="small"
          type="primary"
          plain
        ></el-button>
        <label class="label">子任务编辑</label>
      </div>
      <!--      <div>-->
      <!--        <el-button class="btn" type="primary" size="small" @click="saveOperation()">关闭主任务</el-button>-->
      <!--      </div>-->
    </div>
    <div class="js-common-content">
      <div class="module chapter">
        <div class="module-header">
          <label>子任务</label>
          <i
            class="el-icon-plus"
            v-if="this.status != '2'"
            @click.stop="addSubtask()"
          ></i>
        </div>
        <ul>
          <li
            v-for="(currentSubtask, index) in subtaskArr"
            :key="currentSubtask.id"
          >
            <div
              class="level level1"
              @click.stop="getSubContent(currentSubtask)"
            >
              <span
                >{{ index + 1 }} {{ currentSubtask.nameText }}
                {{ currentSubtask.id }}</span
              >
              <div>
                <i
                  class="el-icon-delete"
                  @click.stop="handleDelete(currentSubtask)"
                ></i>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="module process">
        <div class="module-header">
          <label>详情</label>
          <div class="btns">
            <el-button
              @click="sendSubtask()"
              size="small"
              :disabled="distributionButtonFlag"
              >派发消息</el-button
            >
            <el-button
              @click="closeSubtask()"
              size="small"
              :disabled="closeButtonFlag"
              >关闭任务</el-button
            >
            <el-button
              @click="handleDelete(currentSubtask)"
              size="small"
              :disabled="deleteButtonFlag"
              >删除子任务</el-button
            >
          </div>
        </div>
        <div class="process-content" style="overflow-y: auto">
          <div v-if="!currentSubtask.id" class="empty">请选择一项子任务</div>

          <el-form
            v-show="currentSubtask.id"
            ref="ruleForm"
            :model="currentSubtask"
            :rules="rules"
            label-width="150px"
          >
            <el-form-item label="活动名称：" prop="name">
              <el-input
                v-model="currentSubtask.name"
                placeholder="请输入活动名称"
                :disabled="saveButtonFlag"
              ></el-input>
            </el-form-item>
            <el-form-item label="子任务内容：" prop="content">
              <el-input
                v-model="currentSubtask.content"
                placeholder="请输入子任务内容"
                :disabled="saveButtonFlag"
              ></el-input>
            </el-form-item>
            <el-form-item label="预计开始时间：" prop="planStart">
              <el-date-picker
                class="common-tip"
                v-model="currentSubtask.planStart"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="saveButtonFlag"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="预计结束时间：" prop="planEnd">
              <el-date-picker
                class="common-tip"
                v-model="currentSubtask.planEnd"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="saveButtonFlag"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="录像类型：" prop="recordingType">
              <el-select
                v-model="currentSubtask.recordingType"
                placeholder="请选择"
                size="small"
                :disabled="saveButtonFlag"
              >
                <el-option
                  v-for="item in recordTypeOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="执行人：" prop="userIds">
              <el-select
                v-model="currentSubtask.userIds"
                placeholder="请选择"
                size="small"
                clearable
                multiple
                :disabled="saveButtonFlag"
              >
                <el-option
                  v-for="item in userIdsArr"
                  :key="item.userId"
                  :label="item.realName"
                  :value="item.userId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="附件：" prop="attachList">
              <el-button
                size="small"
                type="primary"
                @click="handleAttManage"
                :disabled="saveButtonFlag"
                >选择附件</el-button
              >
              <li
                class="item"
                v-for="file in currentSubtask.attachList"
                :key="file.id"
              >
                <Xgplayer
                  v-if="isVideo(file.fullUrl)"
                  :config="{
                    id: 'watermelon-item',
                    url: file.fullUrl,
                    autoplay: true,
                    loop: true,
                  }"
                  @player="Player = $event"
                />
                <!-- <video
                  v-if="isVideo(file.fullUrl)"
                  :src="file.fullUrl"
                  autoplay="true"
                  loop="true"
                  class="video"
                ></video>
                 -->
                <img
                  v-else
                  :src="file.fullUrl"
                  alt=""
                  style="width: 50px; margin: 5px; height: 50px"
                />
                <!-- {{file.name}} -->
                <i
                  style="cursor: pointer"
                  class="el-icon-close remove"
                  @click="deleteImage(file)"
                  :disabled="saveButtonFlag"
                ></i>
              </li>
            </el-form-item>
            <div>
              <el-button
                class="btn"
                type="primary"
                size="small"
                @click="saveOperation()"
                :disabled="saveButtonFlag"
                >保存修改</el-button
              >
            </div>
            <br />
            <div
              class="time-line-detail"
              v-for="(executor, ind) in currentSubtask.taskExecutorRecordList"
              :key="ind"
            >
              <p class="time-line-name">执行人: {{ executor.userName }}</p>
              <p class="time-line-name">
                执行状态: {{ executor.status }} (1接受, 2不接受, 3执行者,
                4执行完成(客户端), 5关闭)
              </p>
              <p class="time-line-name">
                执行人总执行时间：{{ executor.executeStart }} -
                {{ executor.executeEnd }}
              </p>
              <div
                class="time-line-name"
                v-for="(recording, reind) in executor.recordingList"
                :key="reind"
              >
                <label @click="handleSrc(recording.fullVideoUrl)"
                  >执行记录 {{ reind + 1 }} {{ recording.cloudRecordStart }}
                  {{ recording.cloudRecordEnd }}</label
                >
                <!-- <video-player
                  v-if="recording.fullVideoUrl != null"
                  class="video-player vjs-custom-skin"
                  ref="videoPlayer"
                  :playsinline="true"
                  :options="playerOptions"
                  poster="@/assets/images/9.png"
                ></video-player> -->
                <Xgplayer
                  v-if="recording.fullVideoUrl != null"
                  :config="{
                    id: 'watermelon',
                    url: videoUrl,
                  }"
                  @player="Player = $event"
                />

                <div v-if="recording.fullImgUrls != null">
                  <li
                    v-for="(eximg, eximgid) in recording.fullImgUrls"
                    :key="eximgid"
                  >
                    <img class="img" :src="eximg" />
                  </li>
                </div>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <att-manage
      v-model="attDialogVisible"
      :fileList="fileList"
      @attManageSearch="attManageSearch"
      @receiveFileUrl="receiveFileUrl"
    ></att-manage>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import RTMClient from "@/assets/js/RTMClient";
import AttManage from "@/components/EventAttachManagement";
import Xgplayer from "xgplayer-vue";

export default {
  name: "",
  components: {
    AttManage,
    Xgplayer,
  },
  data() {
    return {
      videoUrl: "",
      status: 0,
      recordTypeOption: [
        {
          value: 0,
          label: "不录制",
        },
        {
          value: 1,
          label: "本地录制",
        },
        {
          value: 2,
          label: "云端录制",
        },
      ],
      rules: {
        name: [
          { required: true, message: "请输入子任务名称", trigger: "blur" },
        ],
        content: [
          { required: true, message: "请输入子任务内容", trigger: "blur" },
        ],
        planStart: [
          { required: true, message: "请选择开始时间", trigger: "blur" },
        ],
        planEnd: [
          { required: true, message: "请选择结束时间", trigger: "blur" },
        ],
        recordingType: [],
        userIds: [
          { required: true, message: "请至少选择一个执行人", trigger: "blur" },
        ],
      },
      currentID: "",
      subtaskArr: [],
      userIdsArr: [],
      saveButtonFlag: true,
      distributionButtonFlag: true,
      closeButtonFlag: true,
      deleteButtonFlag: true,
      currentSubtask: {
        name: "",
        content: "",
        planEnd: "",
        planStart: "",
        recordingType: "0",
        userIds: [],
        taskId: "",
        attachList: [],
        attachIds: [],
        taskExecutorRecordList: [],
      },
      subContent: "",
      fileIdArr: [],
      modify: false,
      arrIsEmpty: false,
      attDialogVisible: false,
      subtaskDeleteDialogVisible: false,
      userIds: [],
      fileList: [],
      currentFileList: [],
      rtmCommonChannel: "",
      rtmCommonClient: "",
      message: {
        mode: "subtask",
        id: "",
        name: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getUserInfo", "getRtcOptions"]),
  },
  mounted() {
    const { id, isModify, status } = this.$route.query;
    this.currentID = id;
    this.modify = eval(isModify);
    this.status = status;
    this._initPage();
  },

  methods: {
    _initPage() {
      this.getSubtasks();
      this.getReporter();
      this.rtmClient();
    },

    async rtmClient() {
      this.rtmCommonClient = await RTMClient();
      // if(!this.rtmCommonChannel){
      //   let data = this.getUserInfo
      //   this.rtmCommonChannel = this.rtmCommonClient.createChannel(data.rtmChannel)
      //   await this.rtmCommonChannel.join()
      // }else{
      //   await this.rtmCommonChannel.join()
      // }
    },

    //附件管理
    handleAttManage() {
      this.attDialogVisible = true;
      this.getFileList({
        name: "",
        type: "",
      });
    },

    deleteImage(file) {
      console.log(this.currentSubtask.attachIds);
      this.currentSubtask.attachList = this.currentSubtask.attachList.filter(
        (item) => item.id != file.id
      );
      this.currentSubtask.attachIds = this.currentSubtask.attachIds.filter(
        (item) => item != file.id
      );
      console.log(this.currentSubtask.attachIds);
    },

    getFileList() {
      this.$axios({
        method: "get",
        url: "api/csp/task/v1/taskAttach/page",
        params: {
          page: 1,
          perPage: 20,
        },
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.fileList = data.data.rows;

          this.fileList.forEach((item) => {
            item.isCur = false;
          });
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },

    attManageSearch() {
      this.getFileList();
    },
    receiveFileUrl(file) {
      console.log(file);
      let ifsame = this.fileIdArr.some(function (item) {
        return item == file.id;
      });
      if (ifsame == true) {
        this.$message({
          type: "error",
          message: "您已经提交过相同文件",
          duration: 1000,
        });
      } else {
        if (this.currentSubtask.attachIds == null) {
          this.currentSubtask.attachIds = [];
          this.currentSubtask.attachList = [];
        }
        console.log("log");
        this.fileIdArr.push(file.id);
        this.currentSubtask.attachIds.push(file.id);
        this.currentSubtask.attachList.push(file);
      }
    },

    // 获取子任务树
    getSubtasks() {
      this.$axios({
        method: "get",
        url: `api/csp/task/v1/subtask/all/${this.currentID}`,
      }).then((response) => {
        let data = response.data;
        this.subtaskArr = data.data.map((item) => {
          item.nameText = item.name;
          return item;
        });
        if (Object.keys(this.subtaskArr).length == 0) {
          this.arrIsEmpty = true;
          console.log(this.arrIsEmpty);
        }
      });
    },

    getSubContent(item) {
      this.$refs["ruleForm"].resetFields();
      this.currentSubtask = item;
      this.$axios({
        method: "get",
        url: `api/csp/task/v1/subtask/${item.id}`,
      }).then((response) => {
        let data = response.data;
        this.subContent = data.data;
        this.currentSubtask = data.data;
        if (this.subContent.attachList != null) {
          for (let i of this.subContent.attachList) {
            this.fileIdArr.push(i.id);
          }
        }
        let status = data.data.status;
        //状态默认0未派发,1已派发,2关闭
        if (status == 0) {
          //未派发 保存按钮显示
          this.saveButtonFlag = false;
          this.distributionButtonFlag = false;
          this.closeButtonFlag = true;
          this.deleteButtonFlag = false;
          // this.distributionButtonFlag = true
          // 保存修改=true
        } else if (status == 1) {
          //已派发的不可以编辑
          this.saveButtonFlag = true;
          //同一个任务可以派发多次吗? 可以
          this.distributionButtonFlag = true;
          this.deleteButtonFlag = true;
          //可以强制关闭
          this.closeButtonFlag = false;
        } else if (status == 2) {
          this.saveButtonFlag = true;
          this.distributionButtonFlag = true;
          this.closeButtonFlag = true;
          this.deleteButtonFlag = true;
          this.$notify({
            title: "子任务关闭提示",
            message: "该子任务已关闭,只可以查看",
            position: "bottom-right",
          });
        }

        //加一个状态判断, 如果是关闭的显示
        this.currentSubtask.taskExecutorRecordList =
          data.data.taskExecutorRecordList;
      });
    },

    getReporter() {
      this.$axios({
        method: "get",
        url: `api/csp/task/v1/taskExecutor/users`,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.userIdsArr = data.data;
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },

    // 章节添加
    addSubtask() {
      let tmp = {
        content: "",
        planEnd: "",
        name: "",
        planStart: "",
        recordingType: "",
        taskAttachIds: [],
        userIds: [],
        attachList: [],
      };
      this.currentSubtask = tmp;
      this.subtaskArr.push(tmp);
      this.saveAddSubtask(tmp);
    },

    // 添加章节保存
    saveAddSubtask(tmp) {
      this.$axios({
        method: "post",
        url: `api/csp/task/v1/subtask/`,
        data: {
          data: {
            taskId: this.currentID,
            name: this.currentSubtask.name,
            content: this.currentSubtask.content,
            planEnd: this.currentSubtask.planEnd,
            planStart: this.currentSubtask.planStart,
            recordingType: this.currentSubtask.recordingType,
            attachIds: this.currentSubtask.attachIds,
            userIds: this.currentSubtask.userIds,
          },
        },
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.$message({
            type: "success",
            message: "添加子任务成功！",
            duration: 1000,
          });
          this.getSubtasks();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },

    saveOperation() {
      return new Promise((resolve, reject) => {
        // 校验
        if (!this.currentSubtask.id) {
          this.$message({
            type: "error",
            message: "请选择一个子任务！",
            duration: 1000,
          });
          reject();
          return;
        }

        this.$refs["ruleForm"].validate((valid) => {
          if (!valid) {
            reject();
            return;
          }
          // 校验成功
          this.$axios({
            method: "put",
            url: `api/csp/task/v1/subtask/${this.currentSubtask.id}`,
            data: {
              data: {
                content: this.currentSubtask.content,
                name: this.currentSubtask.name,
                planEnd: this.currentSubtask.planEnd,
                planStart: this.currentSubtask.planStart,
                recordingType: this.currentSubtask.recordingType,
                taskId: this.currentID,
                userIds: this.currentSubtask.userIds,
                attachIds: this.currentSubtask.attachIds,
              },
            },
          }).then((response) => {
            let data = response.data;
            if (!data.status) {
              // 保存成功后的回调
              this.$message({
                type: "success",
                message: "子任务修改成功！",
                duration: 1000,
              });
              this.getSubtasks();
              this.saveButtonFlag = false;
              this.distributionButtonFlag = false;
              this.closeButtonFlag = true;
              this.deleteButtonFlag = false;
              resolve(response.data);
            } else {
              this.$message({
                type: "error",
                message: data.msg,
                duration: 1000,
              });
              reject(response);
            }
          });
        });
      });
    },

    //删除章节
    handleDelete(item) {
      this.currentSubtask = item;
      // this.sectionDeleteDialogVisible = true
      this.handleDeleteSubtask();
    },
    handleDeleteSubtask() {
      this.$axios({
        method: "delete",
        url: `api/csp/task/v1/subtask/${this.currentSubtask.id}`,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.currentSubtask = "";
          this.sectionDeleteDialogVisible = false;
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
      this.$router.go(0);
    },

    getChannel() {
      return new Promise((resolve, reject) => {
        this.$axios({
          method: "get",
          url: `api/csp/rc/v1/session/channel`,
        })
          .then((response) => {
            let data = response.data;
            if (!data.status) {
              if (data.data && data.data.channel) {
                this.rtcChannel = data.data.channel;
              }
            } else {
              this.$message({
                type: "error",
                message: data.msg,
                duration: 1000,
              });
            }
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },

    async sendSubtask() {
      // 派发前先走保存逻辑
      this.saveOperation().then(() => {
        for (let item of this.currentSubtask.userIds) {
          this.message = {
            mode: "subtask",
            id: this.currentSubtask.id,
            name: this.currentSubtask.name,
          };
          this.rtmCommonClient
            .sendMessageToPeer(
              {
                text: JSON.stringify(this.message),
              },
              JSON.stringify(item)
            )
            .then((sendResult) => {
              if (sendResult.hasPeerReceived) {
                console.log("远端用户已收到");
                this.subDistribution(this.currentSubtask.id);
              } else {
                console.log("服务器收到，对端未收到");
              }
            });
        }
        this.saveButtonFlag = true;
        this.distributionButtonFlag = true;
        this.closeButtonFlag = true;
        this.deleteButtonFlag = true;
      });
    },

    subDistribution(id) {
      this.$axios({
        method: "post",
        url: `api/csp/task/v1/subtask/distribution/${id}`,
      }).then((response) => {
        let data = response.data;
        if (data.status == 0) {
          console.log("子任务成功派发");
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },

    closeSubtask() {
      this.$axios({
        method: "post",
        url: `api/csp/task/v1/subtask/close/${this.currentSubtask.id}`,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
          this.saveButtonFlag = true;
          this.distributionButtonFlag = true;
          this.closeButtonFlag = true;
          this.deleteButtonFlag = true;
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },

    handleSrc(url) {
      this.videoUrl = url;
    },
    isVideo(url) {
      const arr = url.split(".");
      return arr[arr.length - 1] === "mp4";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standardOpe.scss";

.process-content {
  padding: 20px 20px 20px 0;
  overflow-y: auto;
  .empty {
    height: 400px;
    font-size: 15px;
    text-align: center;
    color: #999999;
    font-family: Helvetica, "Hiragino Sans GB", "Microsoft Yahei", "微软雅黑",
      Arial, sans-serif;
  }
}
.video {
  width: 120px;
  height: 120px;
}
</style>

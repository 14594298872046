var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap js-standard-operation" },
    [
      _c("div", { staticClass: "js-common-head" }, [
        _c(
          "div",
          [
            _c("el-button", {
              staticClass: "back-btn",
              attrs: {
                icon: "el-icon-arrow-left",
                size: "small",
                type: "primary",
                plain: "",
              },
              on: { click: _vm.$goBack },
            }),
            _c("label", { staticClass: "label" }, [_vm._v("子任务编辑")]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "js-common-content" }, [
        _c("div", { staticClass: "module chapter" }, [
          _c("div", { staticClass: "module-header" }, [
            _c("label", [_vm._v("子任务")]),
            this.status != "2"
              ? _c("i", {
                  staticClass: "el-icon-plus",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.addSubtask()
                    },
                  },
                })
              : _vm._e(),
          ]),
          _c(
            "ul",
            _vm._l(_vm.subtaskArr, function (currentSubtask, index) {
              return _c("li", { key: currentSubtask.id }, [
                _c(
                  "div",
                  {
                    staticClass: "level level1",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.getSubContent(currentSubtask)
                      },
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(index + 1) +
                          " " +
                          _vm._s(currentSubtask.nameText) +
                          " " +
                          _vm._s(currentSubtask.id)
                      ),
                    ]),
                    _c("div", [
                      _c("i", {
                        staticClass: "el-icon-delete",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleDelete(currentSubtask)
                          },
                        },
                      }),
                    ]),
                  ]
                ),
              ])
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "module process" }, [
          _c("div", { staticClass: "module-header" }, [
            _c("label", [_vm._v("详情")]),
            _c(
              "div",
              { staticClass: "btns" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "small",
                      disabled: _vm.distributionButtonFlag,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.sendSubtask()
                      },
                    },
                  },
                  [_vm._v("派发消息")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", disabled: _vm.closeButtonFlag },
                    on: {
                      click: function ($event) {
                        return _vm.closeSubtask()
                      },
                    },
                  },
                  [_vm._v("关闭任务")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", disabled: _vm.deleteButtonFlag },
                    on: {
                      click: function ($event) {
                        return _vm.handleDelete(_vm.currentSubtask)
                      },
                    },
                  },
                  [_vm._v("删除子任务")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "process-content",
              staticStyle: { "overflow-y": "auto" },
            },
            [
              !_vm.currentSubtask.id
                ? _c("div", { staticClass: "empty" }, [
                    _vm._v("请选择一项子任务"),
                  ])
                : _vm._e(),
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentSubtask.id,
                      expression: "currentSubtask.id",
                    },
                  ],
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.currentSubtask,
                    rules: _vm.rules,
                    "label-width": "150px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动名称：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入活动名称",
                          disabled: _vm.saveButtonFlag,
                        },
                        model: {
                          value: _vm.currentSubtask.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentSubtask, "name", $$v)
                          },
                          expression: "currentSubtask.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "子任务内容：", prop: "content" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入子任务内容",
                          disabled: _vm.saveButtonFlag,
                        },
                        model: {
                          value: _vm.currentSubtask.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentSubtask, "content", $$v)
                          },
                          expression: "currentSubtask.content",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "预计开始时间：", prop: "planStart" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "common-tip",
                        attrs: {
                          type: "datetime",
                          placeholder: "选择日期时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          disabled: _vm.saveButtonFlag,
                        },
                        model: {
                          value: _vm.currentSubtask.planStart,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentSubtask, "planStart", $$v)
                          },
                          expression: "currentSubtask.planStart",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "预计结束时间：", prop: "planEnd" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "common-tip",
                        attrs: {
                          type: "datetime",
                          placeholder: "选择日期时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          disabled: _vm.saveButtonFlag,
                        },
                        model: {
                          value: _vm.currentSubtask.planEnd,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentSubtask, "planEnd", $$v)
                          },
                          expression: "currentSubtask.planEnd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "录像类型：", prop: "recordingType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            size: "small",
                            disabled: _vm.saveButtonFlag,
                          },
                          model: {
                            value: _vm.currentSubtask.recordingType,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentSubtask, "recordingType", $$v)
                            },
                            expression: "currentSubtask.recordingType",
                          },
                        },
                        _vm._l(_vm.recordTypeOption, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "执行人：", prop: "userIds" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            size: "small",
                            clearable: "",
                            multiple: "",
                            disabled: _vm.saveButtonFlag,
                          },
                          model: {
                            value: _vm.currentSubtask.userIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentSubtask, "userIds", $$v)
                            },
                            expression: "currentSubtask.userIds",
                          },
                        },
                        _vm._l(_vm.userIdsArr, function (item) {
                          return _c("el-option", {
                            key: item.userId,
                            attrs: { label: item.realName, value: item.userId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "附件：", prop: "attachList" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "small",
                            type: "primary",
                            disabled: _vm.saveButtonFlag,
                          },
                          on: { click: _vm.handleAttManage },
                        },
                        [_vm._v("选择附件")]
                      ),
                      _vm._l(_vm.currentSubtask.attachList, function (file) {
                        return _c(
                          "li",
                          { key: file.id, staticClass: "item" },
                          [
                            _vm.isVideo(file.fullUrl)
                              ? _c("Xgplayer", {
                                  attrs: {
                                    config: {
                                      id: "watermelon-item",
                                      url: file.fullUrl,
                                      autoplay: true,
                                      loop: true,
                                    },
                                  },
                                  on: {
                                    player: function ($event) {
                                      _vm.Player = $event
                                    },
                                  },
                                })
                              : _c("img", {
                                  staticStyle: {
                                    width: "50px",
                                    margin: "5px",
                                    height: "50px",
                                  },
                                  attrs: { src: file.fullUrl, alt: "" },
                                }),
                            _c("i", {
                              staticClass: "el-icon-close remove",
                              staticStyle: { cursor: "pointer" },
                              attrs: { disabled: _vm.saveButtonFlag },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteImage(file)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: {
                            type: "primary",
                            size: "small",
                            disabled: _vm.saveButtonFlag,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.saveOperation()
                            },
                          },
                        },
                        [_vm._v("保存修改")]
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _vm._l(
                    _vm.currentSubtask.taskExecutorRecordList,
                    function (executor, ind) {
                      return _c(
                        "div",
                        { key: ind, staticClass: "time-line-detail" },
                        [
                          _c("p", { staticClass: "time-line-name" }, [
                            _vm._v("执行人: " + _vm._s(executor.userName)),
                          ]),
                          _c("p", { staticClass: "time-line-name" }, [
                            _vm._v(
                              " 执行状态: " +
                                _vm._s(executor.status) +
                                " (1接受, 2不接受, 3执行者, 4执行完成(客户端), 5关闭) "
                            ),
                          ]),
                          _c("p", { staticClass: "time-line-name" }, [
                            _vm._v(
                              " 执行人总执行时间：" +
                                _vm._s(executor.executeStart) +
                                " - " +
                                _vm._s(executor.executeEnd) +
                                " "
                            ),
                          ]),
                          _vm._l(
                            executor.recordingList,
                            function (recording, reind) {
                              return _c(
                                "div",
                                { key: reind, staticClass: "time-line-name" },
                                [
                                  _c(
                                    "label",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleSrc(
                                            recording.fullVideoUrl
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "执行记录 " +
                                          _vm._s(reind + 1) +
                                          " " +
                                          _vm._s(recording.cloudRecordStart) +
                                          " " +
                                          _vm._s(recording.cloudRecordEnd)
                                      ),
                                    ]
                                  ),
                                  recording.fullVideoUrl != null
                                    ? _c("Xgplayer", {
                                        attrs: {
                                          config: {
                                            id: "watermelon",
                                            url: _vm.videoUrl,
                                          },
                                        },
                                        on: {
                                          player: function ($event) {
                                            _vm.Player = $event
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  recording.fullImgUrls != null
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          recording.fullImgUrls,
                                          function (eximg, eximgid) {
                                            return _c("li", { key: eximgid }, [
                                              _c("img", {
                                                staticClass: "img",
                                                attrs: { src: eximg },
                                              }),
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      )
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("att-manage", {
        attrs: { fileList: _vm.fileList },
        on: {
          attManageSearch: _vm.attManageSearch,
          receiveFileUrl: _vm.receiveFileUrl,
        },
        model: {
          value: _vm.attDialogVisible,
          callback: function ($$v) {
            _vm.attDialogVisible = $$v
          },
          expression: "attDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }